<template>
  <b-overlay :show="fetching">
    <div class="d-flex justify-content-end">
      <b-button
        v-b-modal.create-edit-language
        variant="outline-primary"
      >
        <feather-icon icon="PlusIcon" />  Create Language
      </b-button>
    </div>
    <h5>Language</h5>
    <b-card no-body>
      <b-table
        :fields="columns"
        :items="tableRows"
      >
        <template #cell(actions)="data">
          <b-button
            v-b-modal.create-edit-language
            variant="outline-secondary"
            class="p-1"
            @click="selectLanguage(data.item)"
          >
            <feather-icon
              icon="EditIcon"
            />
          </b-button>
        </template>
      </b-table>
    </b-card>
    <b-modal
      id="create-edit-language"
      :title="mode === 'create' ? 'Create Language' : 'Edit Language'"
      @hidden="closeModal"
    >
      <validation-observer ref="simpleRules">
        <b-row
          v-for="(input, index) of inputs"
          :key="index"
          class="mb-1"
        >
          <b-col cols="6">
            <b-form-group label="Language Name">
              <validation-provider
                #default="{ errors }"
                name="Language name"
                rules="required"
                :vid="`Language-name-${index}`"
              >
                <b-form-input
                  v-model="input.name"
                  placeholder="Language Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="5">
            <b-form-group label="Code">
              <validation-provider
                #default="{ errors }"
                name="Language code"
                rules="required"
                :vid="`Language-code-${index}`"
              >
                <b-form-input
                  v-model="input.code"
                  placeholder="Language code"
                  :disabled="mode === 'edit'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="index > 0"
            cols="1"
          >
            <feather-icon
              v-b-tooltip.hover="'Remove Language'"
              icon="TrashIcon"
              class="text-danger"
              role="button"
              @click="removeMore(index)"
            />
          </b-col>
        </b-row>
        <b-button
          v-if="mode === 'create'"
          variant="outline-primary"
          @click="addMore"
        >
          <feather-icon icon="PlusIcon" /> Add Language
        </b-button>
      </validation-observer>
      <template #modal-footer="{}">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button @click="() => $bvModal.hide('create-edit-language')">
              Cancel
            </b-button>
            <b-button
              variant="primary"
              class="ml-2"
              :disabled="processing"
              @click="handleConfirm"
            >
              <b-spinner
                v-show="processing"
                small
              />
              Confirm
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </b-overlay>
</template>
<script>
import {
  BButton,
  BModal,
  BFormInput,
  BSpinner,
  BFormGroup,
  BTable,
  BCard,
  BOverlay,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useApollo from '@/plugins/graphql/useApollo'

export default {
  components: {
    BButton,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BFormGroup,
    BTable,
    BCard,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      processing: false,
      required,
      inputs: [
        {
          name: '',
          code: '',
        },
      ],
      columns: [
        { key: 'name', text: 'Name' },
        { key: 'code', text: 'Code' },
        { key: 'actions', text: 'Actions' },
      ],
      fetching: true,
      tableRows: [],
      mode: 'create',
    }
  },
  mounted() {
    this.getLanguages()
  },
  methods: {
    addMore() {
      this.inputs.push({
        name: '',
        code: '',
      })
    },
    getLanguages() {
      this.fetching = true
      useApollo.jobs
        .getLanguages()
        .then(response => {
          this.tableRows = response.data.languages.data
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.fetching = false
        })
    },
    removeMore(index) {
      this.inputs.splice(index, 1)
    },
    handleConfirm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.processing = true
          useApollo.jobs
            .addLanguages(this.inputs)
            .then(response => {
              this.getLanguages()
              this.$bvModal.hide('create-edit-language')
              if (this.mode === 'create') this.showSuccess(response?.data?.registerLanguages?.message)
              else this.showSuccess('Language has been updated successfully')
            })
            .catch(error => {
              this.showErrorMessage(error)
            })
            .finally(() => {
              this.processing = false
            })
        }
      })
    },
    selectLanguage(item) {
      this.mode = 'edit'
      this.inputs = [
        { name: item.name, code: item.code },
      ]
    },
    closeModal() {
      this.mode = 'create'
      this.inputs = [
        { name: '', code: '' },
      ]
    },
  },
}
</script>
