<template>
  <b-tabs pills>
    <b-tab
      v-for="category of categories"
      :key="category.value"
      :title="category.text"
    >
      <component :is="category.component" />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import Country from './Types/Country.vue'
import Language from './Types/Language.vue'

export default {
  components: {
    BTab,
    BTabs,
    Country,
    Language,
  },
  data() {
    return {
      categories: [
        {
          text: 'Country',
          value: 'country',
          component: 'Country',
        },
        {
          text: 'Language',
          value: 'language',
          component: 'Language',
        },
      ],
    }
  },
}
</script>
